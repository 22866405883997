import "./Slider.css";
function Slider() {
  return (
    <section>
        <article>
           <ul>
              <h1>Sell Your Best NFT</h1>
              <h1>Discover Your Best NFT</h1>
              <h1>Create Your Best NFT</h1>
              <h1>Discover Your Best NFT</h1>
            </ul>
        </article>
      </section>
  )
}

export default Slider;