import './heroloader.css'

const HeroLoader = ()=>{
return(

       <div className='spin2'>
       <div className="loaders2"></div>
       </div>
)
}

export default HeroLoader