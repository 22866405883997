import "./loader.css";

const Loader = () => {
  return (
    <div className="spin">
        <span className="loaders"></span>
      {/* <div class="center-body">
        <div class="loader-circle-51"></div>
      </div> */}
    </div>
  );
};

export default Loader;
