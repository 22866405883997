import Pics1 from "../collection-image/profile1.png";
import Pics2 from "../collection-image/profile2.png";
import Pics3 from "../collection-image/profile3.png";
import Pics4 from "../collection-image/profile4.png";

import NFT1 from "../collection-image/nft1.jpeg";
import NFT2 from "../collection-image/nft2.jpeg";
import NFT3 from "../collection-image/nft3.jpeg";
import NFT4 from "../collection-image/nft4.jpeg";
import NFT5 from "../collection-image/nft5.png";
import NFT6 from "../collection-image/nft6.png";
import NFT7 from "../collection-image/nft7.jpg";
import NFT8 from "../collection-image/nft8.jpg";
import NFT9 from "../collection-image/nft9.jpg";
import NFT10 from "../collection-image/nft10.jpg";
import NFT11 from "../collection-image/nft11.jpg";
import NFT12 from "../collection-image/nft12.jpg";

// collection2 Icons
import Icon1 from "../collection-image/Wallet.png";
import Icon2 from "../collection-image/Add.png";
import Icon3 from "../collection-image/Sell.png";
import Icon4 from "../collection-image/Collection.png";

export const NFTData = [
  {
    id: 1,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics1,
    NFTImg: NFT1,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "photography",
  },
  {
    id: 2,
    CreatorName: "Maria",
    Username: "@Maria_",
    NFtName: "Browred_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT2,
    Bid: "Current Bid",
    Price: "0.78ETH",
    Time: "00h: 23m: 12s",
    categories: "gaming",
  },
  {
    id: 3,
    CreatorName: "Maria",
    Username: "@Maria_",
    NFtName: "Browred_Monk",
    CreatorImg: Pics3,
    NFTImg: NFT3,
    Bid: "Current Bid",
    Price: "0.46ETH",
    Time: "00h: 23m: 12s",
    categories: "pfps",
  },
  {
    id: 4,
    CreatorName: "Maria",
    Username: "@Maria_",
    NFtName: "Distored",
    CreatorImg: Pics4,
    NFTImg: NFT4,
    Bid: "Current Bid",
    Price: "0.71ETH",
    Time: "00h: 23m: 12s",
    categories: "Membership",
  },
  {
    id: 5,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics1,
    NFTImg: NFT1,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "art",
  },
  {
    id: 6,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT2,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "Gaming",
  },
  {
    id: 7,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics3,
    NFTImg: NFT3,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "pfps",
  },
  {
    id: 8,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics4,
    NFTImg: NFT4,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "photography",
  },
  {
    id: 9,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics1,
    NFTImg: NFT1,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "art",
  },
  {
    id: 10,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT2,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "Gaming",
  },
  {
    id: 11,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT5,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "art",
  },
  {
    id: 12,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT6,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "art",
  },
  {
    id: 13,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT7,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "pfps",
  },
  {
    id: 14,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT8,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "pfps",
  },
  {
    id: 15,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT9,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "art",
  },
  {
    id: 16,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT10,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "Gaming",
  },
  {
    id: 17,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT11,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "Gaming",
  },
  {
    id: 18,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT12,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "Gaming",
  },

  {
    id: 19,
    CreatorName: "Maria",
    Username: "@Maria_",
    NFtName: "Browred_Monk",
    CreatorImg: Pics3,
    NFTImg: NFT3,
    Bid: "Current Bid",
    Price: "0.46ETH",
    Time: "00h: 23m: 12s",
    categories: "photography",
  },
  {
    id: 20,
    CreatorName: "Maria",
    Username: "@Maria_",
    NFtName: "Distored",
    CreatorImg: Pics4,
    NFTImg: NFT4,
    Bid: "Current Bid",
    Price: "0.71ETH",
    Time: "00h: 23m: 12s",
    categories: "Photography",
  },
  {
    id: 21,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics1,
    NFTImg: NFT1,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "photography",
  },

  {
    id: 22,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT2,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "membership",
  },
  {
    id: 23,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT5,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "membership",
  },
  {
    id: 24,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT6,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "membership",
  },
  {
    id: 25,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT7,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "membership",
  },
  {
    id: 26,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT8,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "membership",
  },
  {
    id: 27,
    CreatorName: "Murphy_",
    Username: "@Mur234##",
    NFtName: "Oranged_Monk",
    CreatorImg: Pics2,
    NFTImg: NFT9,
    Bid: "Current Bid",
    Price: "1.85ETH",
    Time: "13h: 56m: 52s",
    categories: "membership",
  },
];


export const Uses = [
  {
    id: 1,
    icon: Icon1,
    title: "Set Up Your Wallet",
    dec: "Sign up to set up your personal deposit wallet account and explore the world of nft",
  },
  {
    id: 2,
    icon: Icon2,
    title: "Add Your NFT’s",
    dec: "Mint (upload) your wonderful artworks as a non fungible token to the ethereum block chain with ease.",
  },
  {
    id: 3,
    icon: Icon3,
    title: "Sell Your NFT’s",
    dec: "make your NFTs (artworks) available to buyers in the nft marketplace by pushing them to the market.",
  },
  {
    id: 4,
    icon: Icon4,
    title: "Create Collection",
    dec: " create an awesome collection of NFT on your account by minting you artworks as NFT in ethereum block chain",
  },
];
